import React from 'react'
import { useNavigate } from 'react-router-dom';


const Thankyou = () => {

    const navigate = useNavigate();

    const myTimeout = setTimeout(redirect, 3000);

    function redirect() {
        navigate("/main")
    }

  return (
    <p id="thank-you">Thank you for your addition.</p>
  )
}

export default Thankyou