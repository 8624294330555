import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from "./UserContext";
import Auth from "./auth.js";
import { useNavigate } from 'react-router-dom';


const Main =  () => {

  const [records, setRecords] = useState(null)
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if(user){
      fetchData()
    } 
    else{
      navigate("/")
    }
  }, [user])
  

  const fetchData = async()=>{
    const returnedRecords = await Auth.getRecords(user);
    setRecords(returnedRecords.data)
    console.log(records)
  }
  
  const renderedList = records && records.map((record) => {
    return (
      <tr  key={record.id}>
        <td>{record.attributes.album}</td>
        <td>{record.attributes.artist}</td>
        <td>{record.attributes.year}</td>
      </tr>
    );
  });

  return (
    <main>
        <h1 className="main-title">Belinda's Birthday Records</h1>
        <div className="container">
    <p>Below is the list of albums people have already selected. This is displayed to avoid duplication.</p><p> Please select the “Add to List!” button to add your entry.</p>
    
      <p>
        Handy sites for inspiration:
      </p>
      <a href="https://www.discogs.com/">https://www.discogs.com/</a><br></br>
      <a href="https://www.discrepancy-records.com.au/">https://www.discrepancy-records.com.au/</a>
    
    
    <div className="table-container">
    <table id="recordTable" >
      <thead>
        <tr>
      <th>Album</th>
      <th>Artist</th>
      <th>Year</th>
    </tr>
    </thead>
    <tbody >
      {renderedList}
    </tbody>
    </table>
    </div>
    <button id="submissionLink" onClick={()=>navigate("/submission")}>Add to List!</button>
    </div>
        </main>
  )
  }

export default Main
