import Auth from "./auth.js";
export {Model};

const Model = {
    // dbUrl : 'http://localhost:1337/api/',
    dbUrl : 'https://strapi-cy5f.onrender.com/api/',
    // jobs_url: 'jobs?populate=*',
    // company_url: 'companies?populate=*',
    // job_applications_url:'job-applications',
    records_url: 'records',

    data: {
        records: [],
    },

    //runs the 3 fetch functions for the 3 data types, only runs 'fetchApplications' if user is logged in (not null)
    fetchData: function(user){
        // if(!user) return
        this.getRecords();
    },

    //fetches data from the job-application endpoint filtered by the id of the logged in user
    //passing the JWT in the authorization header to authenticate the request,
    //and saves it to data.job_applications, 
    //then dispatches the custom event 'model updated' to prompt the redraw function in main.js
    getRecords: function(){
        fetch(this.dbUrl+this.records_url+"?fields[0]=album&fields[1]=artist&fields[2]=year",{
            method: 'GET',
            headers: {
                // 'Authorization' : `bearer ${Auth.getJWT()}`,
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then((data) => {
            console.log(data)
            this.data.records = data.data
            let event = new CustomEvent("modelUpdated");
            window.dispatchEvent(event);
        });
    },

    returnRecords: function(){
        return this.data.records
    },

    //makes a post request with the form data to the job-application endpoint, 
    // passing the JWT in the authorization header to authenticate the post,
    //then calls the 'fetchApplication' function to include the new post
    //to the local list of job applications
    addRecord: function(formData, user){
        return fetch(this.dbUrl+this.records_url,{
            method: 'POST',
            headers: {
                'Authorization' : `bearer ${user.jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then((response) =>{
            return response.json();
        })
    },
}