import { Model } from "./model.js";
import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from "./UserContext";
import { useNavigate } from 'react-router-dom';

    
    const Submission = () => {

      const navigate = useNavigate();
      const { user } = useContext(UserContext);

      useEffect(() => {
        if(!user){
          navigate("/")
        }
      }, [user])
      
    

      const [name, setName] = useState("");
      const [artist, setArtist] = useState("");
      const [album, setAlbum] = useState("");
      const [year, setYear] = useState("");
      const [inhand, setInhand] = useState(false);
      const [message, setMessage] = useState("");

      const submitForm = (e)=>{
        e.preventDefault();
      
        const formData = {
          data: {
            name,
            album,
            artist,
            year,
            inhand,
            message,
          },
        };
        console.log(formData);
        Model.addRecord(formData, user);
        navigate("/thank-you")
      };

      return (
        <main>
        <h1 className="main-title">Belinda's Birthday Records</h1>
        <div className="container">
    <p className="submissionText">
      Please enter your selection below of the music record you would like to get Belinda. Your name and message will be kept private though the album
    details will display on the previous list to avoid duplicates. </p> 
    <p className="submissionText">A small book will be printed as part of the gift with the record image and message. If you do
    not have the record as yet, indicate as such and if I come across it in my searching I will get in touch.
    </p>
    
        <form id="form"  onSubmit={submitForm}>
          <div className="field">
            <label htmlFor="name">Your Name</label>
            <input type="text" name="name" id="name" required value={name}
            onChange={(e) => setName(e.target.value)} />
           
          </div>
          <div className="field">
            <label htmlFor="album">Album</label>
            <input type="text" name="album" id="album" required  value={album}
            onChange={(e) => setAlbum(e.target.value)}/>
          </div>
          <div className="field">
            <label htmlFor="artist">Artist</label>
            <input type="text" name="artist" id="artist" required  value={artist}
            onChange={(e) => setArtist(e.target.value)} />
          </div>
          <div className="field">
            <label htmlFor="year">Year</label>
            <input type="text" name="year" id="year" required  value={year}
            onChange={(e) => setYear(e.target.value)}/>
          </div>
          <div className="toggle field">
            <span style={{'lineHeight': '35px'}}>In Hand?</span>
            <label htmlFor="inhand" className="switch">
            <input type="checkbox" name="inhand" id="inhand" className="checkbox"  value={inhand}
            onChange={(e) => setInhand(e.target.value)}/>
            <span className="slider"></span>
          </label>
          </div>
          <div className="field">
          <label htmlFor="message">Message</label>
          <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div id="submissionBtn" className="btn-container">
            
            <button id="submitBtn" type="submit">Submit</button>
          </div>
        </form>
      </div>
        </main>
      )
    }
    
    export default Submission