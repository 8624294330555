import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "./UserContext";
import Auth from "./auth";
import { useNavigate } from "react-router-dom";
import recordImage from './vinyl-recond-half.png';
    

    const SignIn = () => {

        const navigate = useNavigate();
        const [password, setPassword] = useState("");
        const [errorMessage, setErrorMessage] = useState("")
        const {user, setUser } = useContext(UserContext);
        const handlePasswordChange = (e) => setPassword(e.target.value);
    
        useEffect(() => {
            if(user){
                console.log(user)
                navigate("/main");
              }
          }, [user])

        const handleSignIn = async (e) => {
            e.preventDefault();
            try {
              const userDetail = await Auth.login({
                'identifier': 'user',
                'password': `${password}birthday`
              })
              setPassword("");
              setUser(userDetail)
              navigate("/main");
            } catch (exception) {
              console.log("wrong password", exception);
              setErrorMessage("Username or password incorrect")
            }
          }
    
      return (
        <>
        <div className="image-container">
        <img src={recordImage} alt='record-image' className="splash-image"></img>
      </div>
      <main>
      <h1 className="splash-title">Belinda's Birthday Records</h1>
      <div className="signin-form">
        <input
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
        <div className = 'error-msg'>{errorMessage}</div>
          <div className="btn-container">
          <form onSubmit={handleSignIn}>
              <button id="signIn" type="submit">Sign In</button>
              </form>
          </div>
      </div>
    </main>
    </>
      )
    }
    
    export default SignIn