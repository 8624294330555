import './App.css';
import React, { useState } from 'react'
import { UserContext } from './UserContext';
import {Routes, Route} from "react-router-dom";
import Main from './Main';
import SignIn from './SignIn';
import Submission from './Submission';
import Thankyou from './Thankyou';

function App() {
  const [user, setUser] = useState(null);
  return (
    <>
    <UserContext.Provider value={{ user, setUser }}>
     <Routes>
        <Route index path="/" element={<SignIn />}/>
        <Route path="/main" element={<Main/>} />
        <Route path="submission" element={<Submission />} />
        <Route path="thank-you" element={<Thankyou/>} />
      </Routes>
    </UserContext.Provider>
    </>
  );
}

export default App;
