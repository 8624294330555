import axios from "axios";

const dbUrl = 'https://strapi-cy5f.onrender.com/api/'

    //makes a post request with the username and password to the auth/local endpoint, 
    //if the response is 'ok' saves user info to 'userData' and dispatches 'userLogin' custom event
    //if not then add new element to display failed login text
    const login =(authInfo)=>{
        const request = axios.post(dbUrl+'auth/local', authInfo)
        return request.then((response) => response.data);
    }

    const getJWT =() =>{
        console.log(this.userData)
       return this.userData?.jwt;
    }
    const getUser=() =>{
        return this.userData?.user;
     }

     const getRecords = (user) => {
        if (user)
          axios.defaults.headers.common["Authorization"] = `Bearer ${user.jwt}`;
        const request = axios.get(dbUrl +"records"+"?fields[0]=album&fields[1]=artist&fields[2]=year");
        return request.then((response) => response.data);
      };

export default {login, getJWT, getUser, getRecords}
